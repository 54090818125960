import Fade from "react-reveal/Fade";
import Text from "@components/news-stack/rich-content/blocks/Text";
import News from "@components/news-stack/rich-content/blocks/News";
import Video from "@components/news-stack/rich-content/blocks/Video";
import Embed from "@components/news-stack/rich-content/blocks/Embed";
import Image from "@components/news-stack/rich-content/blocks/Image";
import NewsStack from "@components/news-stack/rich-content/blocks/NewsStack";
import React, { useState } from "react";

export interface Block {
  type: string | null;
  props: any;
  key?: number;
}

// registration
const BLOCKS = [Text, News, Video, Embed, Image, NewsStack];
const COMPONENTS = {};
const DEFAULT_PROPS = {};
const BLOCK_NAMES = [];

BLOCKS.forEach((block) => {
  COMPONENTS[block.name] = block.component;
  DEFAULT_PROPS[block.name] = block.defaultProps;
  BLOCK_NAMES.push(block.name);
});

export const Block = ({ data }: { data: Block }) => {
  if (typeof COMPONENTS[data.type] !== "undefined") {
    const component = React.createElement(COMPONENTS[data.type], {
      key: new Date().getTime(),
      ...data.props,
    });

    return (
      <div className={`rich-block rich-block-${data.type} mb-2`}>
        {component}
      </div>
    );
  }

  return null;
};

const RichContent = ({ data }) => {
  const [show, setShow] = useState(true);

  return data.map((section) => (
    <Fade bottom fraction={0.01} key={section.title}>
      <div id={section.id} className="rich-news-stack-section relative z-0">
        <Fade
          opposite
          when={show}
          onReveal={() => {
            setShow(!show);
          }}
          fraction={0.03}
          duration={500}
        >
          <div className="pointer-events-none absolute z-10 h-full w-full rounded-lg bg-primary"></div>
        </Fade>
        <div className={show ? "opacity-0" : "opacity-1 z-50 mb-8"}>
          <div
            className="rounded-lg bg-white py-6 px-4 shadow-custom sm:px-6 md:p-10"
            id="rich-stack-cards"
          >
            <h1 className="mb-4 font-poppins text-3xl font-bold">
              {section.title}
            </h1>
            {section.content.map((block) => (
              <div key={block.key}>
                <Block data={block} />
              </div>
            ))}
          </div>
        </div>
      </div>
    </Fade>
  ));
};

export default RichContent;
